/* eslint-disable import/order */
import React, { useState, useLayoutEffect } from 'react'
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'

// Components
import Content from 'components/Content'
import CustomLink from 'components/CustomLink'

// Third Party
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

function addGradient(imageProp, toTop = false) {
  let gradient;

  if (toTop) {
    gradient = [
      imageProp,
      `linear-gradient(to top, rgba(0, 83, 112, 0) 0%, #008cbc 150%)`
    ].reverse();
  } else {
    gradient = [
      imageProp,
      `linear-gradient(to bottom, rgba(0, 83, 112, 0) 22%, #008cbc 104%)`
    ].reverse();
  }

  return gradient;
}

const Deliver = ({ noLinks, deliver }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.01,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if(isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <section className="deliver">
      <div className="container">
        <h2 className="with-dot mb-4">{deliver.title}</h2>
        <div ref={ref} className="row">
          {deliver.functions.map((_function, key) => (
            <motion.div
              initial={{ y: 100, opacity: 0, transition: { type: 'spring', damping: 20, delay: 0.1 * key } }}
              animate={isVisible || view ? { y: 0, opacity: 1, transition: { type: 'spring', damping: 20, delay: 0.1 * key } } : { y: 100, opacity: 0, transition: { type: 'spring', damping: 20, delay: 0.1 * key } }}
              whileHover={{ scale: 1.05, transition: { delay: 0 } }}
              key={key}
              className="col-md-6 col-lg mb-5"
            >
              {noLinks ? (
                <BackgroundImage
                  className="deliver-card"
                  Tag="div"
                  fluid={addGradient(_function.image.localFile.childImageSharp.fluid)}
                >
                  <span className="deliver-card--title text-center with-dot">{_function.name}</span>
                </BackgroundImage>
              ) : (
                <CustomLink to={`/vacatures/?specialisme=${_function.name}#vacatures`}>
                  <BackgroundImage
                    className="deliver-card"
                    Tag="div"
                    fluid={addGradient(_function.image.localFile.childImageSharp.fluid)}
                  >
                    <span className="deliver-card--title text-center with-dot">{_function.name}</span>
                  </BackgroundImage>
                </CustomLink>
              )}
            </motion.div>
          ))}
          <div className="col-md-12 text-center">
            <Content className="mb-5" content={deliver.before_cta} />
            <Link className="button" to={deliver.cta.linkss}>
              {deliver.cta.label}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Deliver;
