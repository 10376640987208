/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Content from 'components/Content'

import Deliver from 'components/home/Deliver'
// import Case from 'components/home/Case'
import About from 'components/home/About'

// Third Party
import { motion } from 'framer-motion'

import "styles/EmployersTemplate.scss"

const Employers = ({ data }) => {
  const { wordpressPage: page } = data
  const { acf: { hero, deliver, about } } = page;

  return (
    <Layout noVacatures>
      <SEO yoast={{ meta: page.yoast_meta }} />
      <div className="employers-page">
        <section className="hero">
          <Image
            loading="eager"
            fadeIn={false}
            fluid={hero.image.localFile.childImageSharp.fluid}
            className="hero-image"
          />
          <div className="container hero-column hero-text">
            <div className="row justify-content-between align-items-start">
              <motion.div
                initial={{ x: -100, opacity: 0.6 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ type: 'spring', damping: 20 }}
                className="col-sm-12 col-md-8 col-lg-6 col-xl-5 card card--left"
              >
                <h1>
                  <Content className="with-dot-nested" content={hero.text} />
                </h1>

                <Content className="hero-before-cta" content={hero.block.cta.before} />

                <Link className="button hero-cta" to={hero.block.cta.linkss}>
                  {hero.block.cta.label}
                </Link>
              </motion.div>

              <motion.div
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ type: 'spring', damping: 20, delay: .3 }}
                className="col-md-12 ml-md-auto ml-0 col-lg-6 card card--right d-block"
              >
                <h2 className="with-dot mb-4">{hero.block.title}</h2>
                <Content className="mb-4" content={hero.block.content} />
              </motion.div>
            </div>
          </div>
        </section>

        <Deliver noLinks deliver={deliver} />
        {/* <Case klantcase={klantcase} /> */}
        <About about={about} />
      </div>
    </Layout>
  )
}

export default Employers

export const query = graphql`
  query($wordpress_id: Int!) {
    wordpressPage(
      wordpress_id: { eq: $wordpress_id }
    ) {
      id
      wordpress_id
      title
      slug
      content
      yoast_meta {
        name
        content
        property
      }
      acf {
        # Hero section
        hero {
          text
          block {
            content
            title
            cta {
              before
              label
              linkss
            }
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        # Deliver section
        deliver {
          title
          functions {
            name
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          before_cta
          cta {
            label
            linkss
          }
        }

        # Klantcase section
        klantcase: case {
          background {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          cta
          # relations {
          #   slug
          #   path
          #   acf {
          #     quote {
          #       content
          #       quoted_person {
          #         name
          #         function
          #       }
          #     }
          #   }
          # }
        }

        # About section
        about {
          title
          columns {
            title
            content
          }
          cta {
            label
            linkss
          }
        }
      }
    }
  }
`
